import { usePage } from "@inertiajs/react";
import { memo, useContext, useEffect, useState } from "react";

import BB_Alert from "@/Components/BB_Alert";
import BB_Background from "@/Components/BB_Background";
import BB_FloatingButton from "@/Components/BB_FloatingButton";
import BB_Footer from "@/Components/BB_Footer";
import BB_NavBar from "@/Components/BB_NavBar";
import BB_Notification from "@/Components/BB_Notification";
import BB_TabBar from "@/Components/BB_TabBar";
import { LocationContext } from "@/ContextProviders/LocationProvider";
import BB_AvailabilityModal from "@/Modals/AvailabilityModal";
import BB_PopupBlockedModal from "@/Modals/PopupBlockedModal";
import BB_PopupNewTabModal from "@/Modals/PopupNewTabModal";
import PopupWaitingModal from "@/Modals/PopupWaitingModal";
import BB_RemoveAccountModal from "@/Modals/RemoveAccountModal";

type AuthenticatedProps = {
	children?: React.ReactNode;
};

const AuthenticatedLayout = (props: AuthenticatedProps) => {
	const { location } = useContext(LocationContext);
	const pageProps = usePage();
	const [showEmailBounceAlert, setShowEmailBounceAlert] = useState(true);

	useEffect(() => {
		if (location) {
			switch (location.pathname) {
				case "/":
				case "/feed":
					document
						.querySelectorAll(".bell-waist, .bell-clapper, .bell-ring")
						.forEach((el) => {
							(el as HTMLElement).style.animationPlayState = "running";
						});
					break;
				case "/settings":
					setShowEmailBounceAlert(false);
					break;
			}
		}
	}, [location]);

	return (
		<>
			<BB_NavBar />
			<BB_Background />

			<main className="mt-16 pb-8">
				<div className="mx-auto max-w-3xl px-0 sm:py-8 md:px-6 lg:max-w-7xl lg:px-8">
					{(pageProps.props?.auth?.user?.email_bounce_complaint ?? false) === 1 &&
						showEmailBounceAlert && (
							<BB_Alert
								allowClose={false}
								className={"mb-2 max-sm:mt-6 md:mb-4"}
								title={`Leider können wir Dir derzeit keine E-Mails mehr senden, weil Deine E-Mail Adresse nicht erreichbar ist. Bitte <u><a class="text-black!" href="${route("settings")}">ändere Deine E-Mail Adresse</a></u>, um Deinen Account zu schützen und weiterhin E-Mails empfangen zu können.`}
							/>
						)}

					{props.children}
				</div>
			</main>

			<BB_Footer />
			<BB_TabBar isLoggedIn={true} />
			<BB_Notification />

			<BB_FloatingButton />

			<BB_AvailabilityModal />
			<BB_PopupBlockedModal />
			<BB_PopupNewTabModal />
			<PopupWaitingModal />
			<BB_RemoveAccountModal />
		</>
	);
};

export default memo(AuthenticatedLayout);
