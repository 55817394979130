import DOMPurify from "dompurify";
import parse, { domToReact } from "html-react-parser";
import { memo, useEffect, useState } from "react";

import InfoIcon from "@/Icons/info.svg?react";

export type BB_AlertProps = {
	className?: string;
	title?: string;
	allowClose?: boolean;
};

const BB_Alert = (props: BB_AlertProps) => {
	const [showAlert, setShowAlert] = useState(true);
	const [content, setContent] = useState<ReturnType<typeof domToReact>>();

	useEffect(() => {
		if (props.title && props.title?.length > 0) {
			if (typeof window !== "undefined") {
				const cleanHtml = DOMPurify.sanitize(props.title);
				setContent(parse(cleanHtml));
			} else {
				setContent(<div dangerouslySetInnerHTML={{ __html: props.title }}></div>);
			}
		} else {
			setContent(props.title);
		}
	}, []);

	return (
		<>
			{showAlert && (
				<button
					onClick={() => {
						if (props.allowClose) {
							setShowAlert(false);
						}
					}}
					className={`bg-primary-500 w-full p-4 shadow-lg sm:rounded-lg ${props.className ?? ""}`}>
					<div className="flex items-center">
						<div className="shrink-0">
							<InfoIcon className="h-5 w-5 text-black" />
						</div>
						<div className="ml-3 flex-1 md:flex md:justify-between">
							<p className="text-left text-sm text-pretty hyphens-auto text-black">
								{content}
							</p>
						</div>
					</div>
				</button>
			)}
		</>
	);
};

export default memo(BB_Alert);
