import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { useForm, usePage } from "@inertiajs/react";
import { Fragment, memo, useContext, useRef } from "react";
import { z } from "zod";

import BB_Button from "@/Components/BB_Button";
import { ModalContext } from "@/ContextProviders/ModalProvider";
import LockIcon from "@/Icons/lock.svg?react";
import TrashIcon from "@/Icons/trash.svg?react";

const RemoveAccountFormInputs = z.object({
	password: z
		.string({ message: "Das Passwort muss vorhanden sein" })
		.min(8, { message: "Das Passwort muss mindestens 8 Zeichen lang sein" })
		.regex(/\d/, { message: "Das Passwort muss mindestens eine Zahl enthalten" })
		.nonempty({ message: "Das Passwort darf nicht leer sein" }),
	_token: z
		.string({ message: "Das Sicherheits-Token muss vorhanden sein" })
		.nonempty({ message: "Das Sicherheits-Token darf nicht leer sein" })
});

type RemoveAccountFormSchemaType = z.infer<typeof RemoveAccountFormInputs>;
type RemoveAccountFieldName = keyof RemoveAccountFormSchemaType;

type RemoveAccountModalProps = object;

const RemoveAccountModal = (_props: RemoveAccountModalProps) => {
	const pageProps = usePage();
	const { showRemoveAccountModal, setShowRemoveAccountModal } = useContext(ModalContext);
	const formRef = useRef<HTMLFormElement>(null);

	const removeAccountForm = useForm<RemoveAccountFormSchemaType>({
		password: "",
		_token: pageProps.props.session.csrf
	});

	const validateRemoveAccountField = (field: RemoveAccountFieldName, value: unknown) => {
		if (value === undefined || value === null || value === "") {
			removeAccountForm.clearErrors(field);
			return;
		}

		const parsed = RemoveAccountFormInputs.safeParse({ [field]: value });

		if (parsed.success) {
			removeAccountForm.clearErrors(field);
			return;
		}

		const error = parsed.error?.flatten().fieldErrors[field]?.[0];
		if (error) {
			removeAccountForm.setError(field, error);
		}
	};

	const submitAccountRemoval = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		// Perform client-side validation using Zod
		const result = RemoveAccountFormInputs.safeParse(removeAccountForm.data);

		// show clientside errors
		if (!result.success) {
			const newErrors = result.error.formErrors.fieldErrors;
			Object.keys(newErrors).forEach((key) => {
				if (key === "password" && newErrors[key]) {
					removeAccountForm.setError(key, newErrors[key][0]);
				}
			});
			return;
		}

		removeAccountForm.clearErrors();

		removeAccountForm.delete(route("profile.destroy"), {
			onError: () => {
				removeAccountForm.reset("password");
			},
			onFinish: () => {
				removeAccountForm.reset("password");
			}
		});
	};

	return (
		<Transition show={showRemoveAccountModal} as={Fragment} appear>
			<Dialog as="div" className="relative" onClose={() => setShowRemoveAccountModal(false)}>
				{/* Backdrop */}
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-40 dark:opacity-50"
					leave="ease-in duration-200"
					leaveFrom="opacity-40 dark:opacity-50"
					leaveTo="opacity-0">
					<div className="fixed inset-0 z-20 bg-black/40 backdrop-blur-xs transition-opacity dark:bg-black/50" />
				</TransitionChild>

				{/* Centered Dialog */}
				<div className="fixed inset-0 z-30 flex items-center justify-center p-4">
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95">
						<DialogPanel className="bg-background-100 dark:bg-dark-background-300 w-full max-w-md rounded-lg p-6 shadow-lg ring-1 ring-gray-900/5">
							<DialogTitle className="text-lg font-medium text-gray-900 dark:text-gray-200">
								🫣 Konto löschen
							</DialogTitle>
							<p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
								Bist du sicher? Diese Aktion kann nicht rückgängig gemacht werden.
							</p>

							<form
								ref={formRef}
								onSubmit={submitAccountRemoval}
								noValidate
								className="grid grid-cols-1 gap-4 space-y-2">
								<div>
									<label
										htmlFor="password"
										className="mt-4 mb-3 block text-sm font-medium text-gray-900 dark:text-gray-200">
										Passwort eingeben
									</label>
									<div className="relative">
										<div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
											<LockIcon className="size-4" />
										</div>
										<input
											value={removeAccountForm.data.password}
											onChange={(e) =>
												removeAccountForm.setData(
													"password",
													e.target.value
												)
											}
											onFocus={() =>
												removeAccountForm.clearErrors("password")
											}
											onBlur={(e) =>
												validateRemoveAccountField(
													"password",
													e.target.value
												)
											}
											id="password"
											name="password"
											type="password"
											placeholder="Aktuelles Passwort"
											autoComplete="off"
											className="bg-background-50 dark:bg-dark-background-400 focus:ring-primary-500 block w-full rounded-md border-0 py-1.5 ps-10 text-black shadow-xs ring-1 ring-gray-300 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 dark:text-white"
											required
										/>
									</div>
								</div>
								{removeAccountForm.errors.password && (
									<span className="text-sm text-red-500">
										{removeAccountForm.errors.password}
									</span>
								)}

								<div className="flex justify-between space-x-3">
									<BB_Button
										type="secondary"
										buttonClassName="flex-1"
										disabled={removeAccountForm.processing}
										onClick={() => setShowRemoveAccountModal(false)}
										title="Abbrechen"
									/>
									<BB_Button
										type="destructive"
										onClick={() => formRef.current?.requestSubmit()}
										disabled={removeAccountForm.processing}
										buttonClassName="flex-1"
										icon={TrashIcon}
										iconClassName={"h-5 w-5 mr-2 pointer-events-none"}
										title="Permanent löschen"
										ripple={true}
									/>
								</div>
							</form>
						</DialogPanel>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	);
};

export default memo(RemoveAccountModal);
